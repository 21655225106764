<ng-container *transloco="let t">
  <!-- Title -->
  <h2 mat-dialog-title fxLayout="row" fxLayoutAlign="center" color="accent">
    {{ t("lazy.advancedSearchDialog.name") }}
  </h2>

  <!-- Dialog content -->
  <mat-dialog-content
    fxLayout="column"
    fxLayoutAlign="start start"
    fxLayoutGap="16px"
  >
    <!-- Form -->
    <form fxFill fxLayout="column" [formGroup]="advanced_form">
      <!-- Title -->
      <mat-form-field color="accent">
        <mat-label>{{ t("lazy.advancedSearchDialog.title") }}</mat-label>

        <!-- input -->
        <input matInput formControlName="title" />
      </mat-form-field>

      <!-- Author name -->
      <mat-form-field color="accent">
        <mat-label>{{ t("lazy.advancedSearchDialog.author") }}</mat-label>

        <!-- input -->
        <input matInput formControlName="author" />
      </mat-form-field>

      <!-- From -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <span>{{ t("lazy.advancedSearchDialog.from") }}:</span>
        <!-- Year -->
        <mat-form-field style="width: 45px" color="accent">
          <mat-label>YYYY</mat-label>

          <!-- input -->
          <input matInput formControlName="fromYear" />
        </mat-form-field>

        <!-- Month -->
        <mat-form-field
          *ngIf="
            advanced_form.get('fromYear').value &&
            advanced_form.get('fromYear').valid
          "
          style="width: 31px"
          color="accent"
        >
          <mat-label>MM</mat-label>

          <!-- input -->
          <input matInput formControlName="fromMonth" />
        </mat-form-field>

        <!-- Day -->
        <mat-form-field
          *ngIf="
            advanced_form.get('fromMonth').value &&
            advanced_form.get('fromMonth').valid
          "
          style="width: 26px"
          color="accent"
        >
          <mat-label>DD</mat-label>

          <!-- input -->
          <input matInput formControlName="fromDay" />
        </mat-form-field>
      </div>

      <!-- To -->
      <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <span>{{ t("lazy.advancedSearchDialog.to") }}:</span>
        <!-- Year -->
        <mat-form-field style="width: 45px" color="accent">
          <mat-label>YYYY</mat-label>

          <!-- input -->
          <input matInput formControlName="toYear" />
        </mat-form-field>

        <!-- Month -->
        <mat-form-field
          *ngIf="
            advanced_form.get('toYear').value &&
            advanced_form.get('toYear').valid
          "
          style="width: 31px"
          color="accent"
        >
          <mat-label>MM</mat-label>

          <!-- input -->
          <input matInput formControlName="toMonth" />
        </mat-form-field>

        <!-- Day -->
        <mat-form-field
          *ngIf="
            advanced_form.get('toMonth').value &&
            advanced_form.get('toMonth').valid
          "
          style="width: 26px"
          color="accent"
        >
          <mat-label>DD</mat-label>

          <!-- input -->
          <input matInput formControlName="toDay" />
        </mat-form-field>
      </div>
    </form>
  </mat-dialog-content>

  <!-- Action buttons -->
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-evenly center">
    <!-- Cancel button -->
    <button mat-button mat-dialog-close (click)="onNoClick()" color="accent">
      {{ t("lazy.advancedSearchDialog.cancel") }}
    </button>

    <!-- Find button -->
    <button
      mat-button
      color="primary"
      form="advanced_form"
      (click)="onYesClick()"
    >
      {{ t("lazy.advancedSearchDialog.find") }}
    </button>
  </mat-dialog-actions>
</ng-container>
