<ng-container *transloco="let t; read: 'navbar'">
  <ng-container *ngIf="appState$ | async as appState">
    <div
      fxLayout="column"
      fxLayoutAlign="start start"
      class="mobile-sidenav-container"
    >
      <!-- Home button -->
      <div
        *ngIf="appState.isLoggedIn"
        class="mobile-sidenav-button"
        (click)="navigate('/elvira/home')"
      >
        <mat-icon class="mobile-sidenav-icon">home</mat-icon>
        <span>{{ t("home") }}</span>
      </div>

      <!-- Library button -->
      <div
        class="mobile-sidenav-button"
        *ngIf="appState.isLoggedIn"
        (click)="navigateToLibrary()"
      >
        <mat-icon class="mobile-sidenav-icon">menu_book</mat-icon>
        <span>{{ t("library") }}</span>
      </div>

      <!-- My shelf button -->
      <div
        class="mobile-sidenav-button"
        *ngIf="appState.isLoggedIn"
        (click)="navigate('/elvira/my-shelf')"
      >
        <mat-icon class="mobile-sidenav-icon">my_library_books</mat-icon>
        <span>{{ t("myShelf") }}</span>
      </div>

      <!-- Advanced search button -->
      <div
        class="mobile-sidenav-button"
        *ngIf="appState.isLoggedIn"
        (click)="openAdvanced()"
      >
        <mat-icon class="mobile-sidenav-icon"> search </mat-icon>
        <span> {{ t("mobileAdvanced") }} </span>
      </div>

      <!-- Admin button -->
      <div
        class="mobile-sidenav-button"
        *ngIf="appState.isLoggedIn"
        (click)="navigate('/elvira/admin')"
      >
        <span> admin </span>
      </div>

      <!-- down row column -->
      <div fxLayout="column" class="mobile-sidenav-bottom-container">
        <!-- Translate button -->
        <div *ngIf="appState.lang === 'en'; else enVersionBtn">
          <!-- svk -->
          <div class="mobile-sidenav-button" (click)="changeLanguage('sk')">
            <mat-icon class="mobile-sidenav-icon">language</mat-icon>
            <span>{{ t("eng") }}</span>
          </div>
        </div>
        <!-- en -->
        <ng-template #enVersionBtn>
          <div>
            <div class="mobile-sidenav-button" (click)="changeLanguage('en')">
              <mat-icon class="mobile-sidenav-icon">translate</mat-icon>
              <span>{{ t("svk") }}</span>
            </div>
          </div>
        </ng-template>

        <!-- Theme button -->
        <div *ngIf="appState.theme === 'dark'; else darkModeBtn">
          <!-- light -->
          <div class="mobile-sidenav-button" (click)="changeTheme('light')">
            <mat-icon class="mobile-sidenav-icon">dark_mode</mat-icon>
            <span>{{ t("dark") }}</span>
          </div>
        </div>
        <!-- dark -->
        <ng-template #darkModeBtn>
          <div class="mobile-sidenav-button" (click)="changeTheme('dark')">
            <mat-icon class="mobile-sidenav-icon">light_mode</mat-icon>
            <span>{{ t("light") }}</span>
          </div>
        </ng-template>

        <!-- Logout button -->
        <div
          *ngIf="appState.isLoggedIn"
          class="mobile-sidenav-button"
          style="display: flex"
          (click)="logout()"
        >
          <mat-icon class="mobile-sidenav-icon">logout</mat-icon>
          <span>{{ t("log-out") }}</span>
        </div>
      </div>
    </div>
  </ng-container>
</ng-container>
