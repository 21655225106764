<ng-container *transloco="let t">
  <div class="my-shelf-container" fxLayout="column">
    <!-- Tools -->
    <div class="my-shelf-tools-container">
      <div
        class="my-shelf-tools"
        fxLayout="column"
        fxLayout.sm="row"
        fxLayout.xs="row"
      >
        <!-- Tools buttons -->
        <div
          class="my-shelf-tools-buttons-container"
          *ngFor="let button of buttons"
        >
          <mat-icon
            matTooltip="{{ t(button.toolTip) }}"
            fxLayout="column"
            color="accent"
            class="{{ button.class }}"
            [ngClass]="{ 'my-shelf-tools-active-button': button.active }"
            (click)="button.onClick()"
          >
            {{ button.icon }}
            <span class="my-shelf-tools-button-span">{{ button.title }}</span>
          </mat-icon>
        </div>
      </div>
    </div>

    <!-- Form for search -->
    <form
      [formGroup]="searchForm"
      (submit)="submit()"
      fxLayoutAlign="start center"
      fxFlex="100%"
      fxLayout="row"
    >
      <!-- Search form field -->
      <mat-form-field fxFlex.gt-xs="50%" fxFlex.xs="100%" color="accent">
        <mat-label>{{ t("lazy.myShelf.search") }}</mat-label>

        <!-- input -->
        <input matInput formControlName="search" />
      </mat-form-field>

      <!-- Button for submit -->
      <button mat-icon-button type="submit" color="accent">
        <mat-icon>search</mat-icon>
      </button>
    </form>

    <!-- Entries -->
    <div
      fxFlex="100%"
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxLayoutGap="16px grid"
    >
      <ng-container *ngIf="myShelf.length > 0; else empty">
        <div
          fxFlex="14.28%"
          fxFlex.md="25%"
          fxFlex.sm="33.3%"
          fxFlex.xs="100%"
          *ngFor="let shelf of myShelf"
        >
          <app-entry-detail
            [entry]="shelf.entry"
            [show]="true"
            (onLikedChange)="reload()"
          ></app-entry-detail>
        </div>
      </ng-container>

      <!-- If my-shelf are empty -->
      <ng-template #empty>
        <div
          *ngIf="loaded"
          fxLayout="row"
          fxLayoutAlign="center center"
          class="my-shelf-empty-row"
        >
          <h1 class="my-shelf-empty-text">
            {{ t("lazy.myShelf.emptyMyShelf") }}
          </h1>
        </div>
      </ng-template>
    </div>
  </div>
</ng-container>
