<ng-container *transloco="let t">
  <div class="entry-detail-container">
    <mat-card class="entry-detail-card">
      <!-- Image of entry if there is no image -->
      <div
        class="entry-detail-image-container"
        (elviraclick)="openPdf(entry.id, $event)"
      >
        <div *ngIf="image_src === 'none'; else image">
          <img
            mat-card-image
            src="../../../../assets/no_image.png"
            class="entry-detail-image"
          />
        </div>

        <!-- Image if there is image -->
        <ng-template #image>
          <img
            mat-card-image
            [src]="entry.thumbnail"
            class="entry-detail-image"
          />
        </ng-template>
      </div>

      <!-- Feeds list of current entry -->
      <div
        class="entry-detail-content"
        [ngClass]="{ 'entry-detail-content-show': show }"
      >
        <div
          fxLayout="row wrap"
          fxLayoutGap="5px"
          class="entry-detail-feeds-container"
        >
          <div *ngFor="let feed of entry.feeds">
            <div
              class="entry-detail-feed"
              (elviraclick)="navigate(feed.id, $event)"
            >
              {{ feed.title }}
            </div>
          </div>
        </div>
      </div>

      <!-- Buttons -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-around"
        class="entry-detail-actions"
        *ngIf="show"
      >
        <!-- Info button -->
        <button
          mat-icon-button
          matTooltip="{{ t('lazy.entryDetail.info-pdf-tooltip') }}"
          (click)="showInfo(entry.id)"
        >
          <mat-icon> info </mat-icon>
        </button>

        <!-- My shelf button -->
        <button
          *ngIf="!entry.shelf_record_id; else liked"
          mat-icon-button
          matTooltip="{{ t('lazy.entryDetail.myShelf-pdf-tooltip') }}"
          (click)="addToMyShelf(entry.id)"
        >
          <mat-icon> library_add </mat-icon>
        </button>

        <!-- If entry was added to my shelf -->
        <ng-template #liked>
          <button
            mat-icon-button
            matTooltip="{{ t('lazy.entryDetail.remove-MyShelf-pdf-tooltip') }}"
            (click)="removeFromMyShelf(entry.shelf_record_id, entry.id)"
          >
            <mat-icon> remove_circle </mat-icon>
          </button>
        </ng-template>
      </div>
    </mat-card>

    <!-- Entry title -->
    <div
      class="entry-detail-title"
      [ngClass]="{ 'entry-detail-title-one-row': titleRow }"
      [title]="entry.title"
      (elviraclick)="openPdf(entry.id, $event)"
    >
      {{ entry.title }}
    </div>
  </div>
</ng-container>
