<ng-container *transloco="let t">
  <ng-container *ngIf="entryDetail$ | async as entryDetail">

    <!-- Title -->
    <h2 fxLayout="row" fxLayoutAlign="center" class="entry-info-title">{{ entryDetail.response.title }}
    </h2>

    <!-- Dialog content -->
    <mat-dialog-content fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="16px">

      <!-- Author -->
      <div class="entry-info-paragraph" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
        <span class="entry-info-paragraph-title">{{ t("lazy.entryInfoDialog.author") }}</span>
        <span>{{ entryDetail.response.author.name + " " + entryDetail.response.author.surname }}</span>
      </div>

      <!-- Contributors -->
      <div class="entry-info-paragraph" fxLayout="column">
        <span class="entry-info-paragraph-title">{{ t("lazy.entryInfoDialog.contributors") }}</span>
        <div *ngIf="entryDetail.response.contributors.length > 0; else noneContributors">
          <div *ngFor="let contributor of entryDetail.response.contributors">
            <span>{{ contributor.name + " " + contributor.surname }}</span>
          </div>
        </div>
        <ng-template #noneContributors>
          <span>{{ t("lazy.entryInfoDialog.none") }}</span>
        </ng-template>
      </div>

      <!-- Summary -->
      <div class="entry-info-paragraph" fxLayout="column">
        <span class="entry-info-paragraph-title">{{ t("lazy.entryInfoDialog.summary") }}</span>
        <span>{{ entryDetail.response.summary }}</span>
      </div>

      <!-- Feeds list -->
      <div class="entry-info-paragraph">
        <span class="entry-info-paragraph-title">{{ t("lazy.entryInfoDialog.feeds") }}</span>
        <div fxLayout="row wrap" fxLayoutGap="10px">
          <div *ngFor="let feed of entryDetail.response.feeds">
            <div class="entry-info-feed">
              {{ feed.title }}
            </div>
          </div>
        </div>
      </div>

      <!-- Created at date -->
      <div class="entry-info-paragraph" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="16px">
        <span class="entry-info-paragraph-title">{{ t("lazy.entryInfoDialog.createdAt") }}</span>
        <span>{{ entryDetail.response.created_at | date: "dd.MM.yyyy" }}</span>
      </div>
    </mat-dialog-content>

    <!-- Button for closing info -->
    <mat-dialog-actions fxLayout="row" fxLayoutAlign="center center">
      <button mat-button mat-dialog-close color="accent">
        {{ t("lazy.entryInfoDialog.close") }}
      </button>
    </mat-dialog-actions>
  </ng-container>
</ng-container>