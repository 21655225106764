<div fxLayout="column" class="reader-settings-container">

  <!-- Title -->
  <div fxFlex="100" fxLayoutAlign="center center">
    <h1 fxFlex.gt-xs="50" fxFlex.xs="95" fxLayoutAlign="center center" class="reader-settings-title">NASTAVENIE ČÍTAČKY
    </h1>
  </div>

  <!-- Content -->
  <div fxLayout="column" fxFlex="100" fxLayoutAlign="center center">
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum finibus placerat tempus.
      Fusce consequat nunc metus, vel maximus enim finibus in. Etiam at sodales purus, in lobortis magna.
      Maecenas ac molestie urna, et suscipit dolor. Nunc posuere ex sed velit elementum mollis.
      Fusce luctus facilisis venenatis. Quisque ornare dictum ligula, at hendrerit erat venenatis vel.
      Cras non consectetur mauris, id varius dolor. Aliquam augue purus, lobortis quis elit sit amet,
      interdum laoreet eros. Donec ornare consequat ex, ac pellentesque libero mollis eu. Etiam a dictum justo.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum finibus placerat tempus.
      Fusce consequat nunc metus, vel maximus enim finibus in. Etiam at sodales purus, in lobortis magna.
      Maecenas ac molestie urna, et suscipit dolor. Nunc posuere ex sed velit elementum mollis.
      Fusce luctus facilisis venenatis. Quisque ornare dictum ligula, at hendrerit erat venenatis vel.
      Cras non consectetur mauris, id varius dolor. Aliquam augue purus, lobortis quis elit sit amet,
      interdum laoreet eros. Donec ornare consequat ex, ac pellentesque libero mollis eu. Etiam a dictum justo.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum finibus placerat tempus.
      Fusce consequat nunc metus, vel maximus enim finibus in. Etiam at sodales purus, in lobortis magna.
      Maecenas ac molestie urna, et suscipit dolor. Nunc posuere ex sed velit elementum mollis.
      Fusce luctus facilisis venenatis. Quisque ornare dictum ligula, at hendrerit erat venenatis vel.
      Cras non consectetur mauris, id varius dolor. Aliquam augue purus, lobortis quis elit sit amet,
      interdum laoreet eros. Donec ornare consequat ex, ac pellentesque libero mollis eu. Etiam a dictum justo.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum finibus placerat tempus.
      Fusce consequat nunc metus, vel maximus enim finibus in. Etiam at sodales purus, in lobortis magna.
      Maecenas ac molestie urna, et suscipit dolor. Nunc posuere ex sed velit elementum mollis.
      Fusce luctus facilisis venenatis. Quisque ornare dictum ligula, at hendrerit erat venenatis vel.
      Cras non consectetur mauris, id varius dolor. Aliquam augue purus, lobortis quis elit sit amet,
      interdum laoreet eros. Donec ornare consequat ex, ac pellentesque libero mollis eu. Etiam a dictum justo.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum finibus placerat tempus.
      Fusce consequat nunc metus, vel maximus enim finibus in. Etiam at sodales purus, in lobortis magna.
      Maecenas ac molestie urna, et suscipit dolor. Nunc posuere ex sed velit elementum mollis.
      Fusce luctus facilisis venenatis. Quisque ornare dictum ligula, at hendrerit erat venenatis vel.
      Cras non consectetur mauris, id varius dolor. Aliquam augue purus, lobortis quis elit sit amet,
      interdum laoreet eros. Donec ornare consequat ex, ac pellentesque libero mollis eu. Etiam a dictum justo.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum finibus placerat tempus.
      Fusce consequat nunc metus, vel maximus enim finibus in. Etiam at sodales purus, in lobortis magna.
      Maecenas ac molestie urna, et suscipit dolor. Nunc posuere ex sed velit elementum mollis.
      Fusce luctus facilisis venenatis. Quisque ornare dictum ligula, at hendrerit erat venenatis vel.
      Cras non consectetur mauris, id varius dolor. Aliquam augue purus, lobortis quis elit sit amet,
      interdum laoreet eros. Donec ornare consequat ex, ac pellentesque libero mollis eu. Etiam a dictum justo.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum finibus placerat tempus.
      Fusce consequat nunc metus, vel maximus enim finibus in. Etiam at sodales purus, in lobortis magna.
      Maecenas ac molestie urna, et suscipit dolor. Nunc posuere ex sed velit elementum mollis.
      Fusce luctus facilisis venenatis. Quisque ornare dictum ligula, at hendrerit erat venenatis vel.
      Cras non consectetur mauris, id varius dolor. Aliquam augue purus, lobortis quis elit sit amet,
      interdum laoreet eros. Donec ornare consequat ex, ac pellentesque libero mollis eu. Etiam a dictum justo.
    </p>
  </div>
</div>