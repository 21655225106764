<ng-container *transloco="let t; read: 'navbar'">
  <ng-container *ngIf="appState$ | async as appState">
    <mat-toolbar fxLayout="column" class="mobile-navbar-container">
      <!-- TOP ROW -->
      <div
        fxLayout="row"
        fxLayoutAlign="space-between center"
        class="mobile-navbar-upper-row"
      >
        <!-- Image elvira -->
        <img
          src="../../../../assets/logos/title_logo.png"
          [disabled]="!appState.isLoggedIn"
          (click)="navigate('/elvira/home')"
          class="navbar-elvira-img"
        />

        <!-- STU logo -->
        <img
          src="../../../../assets/logos/fiit_logo.png"
          class="mobile-navbar-image-stu-logo"
          (click)="goToSTU()"
        />
      </div>

      <!-- BOTTOM ROW -->
      <div
        fxLayout="row"
        fxLayoutAlign="start center"
        class="mobile-navbar-bottom-row"
      >
        <!-- Search form -->
        <form
          [formGroup]="search_form"
          (submit)="submit()"
          class="mobile-navbar-form"
          fxLayoutAlign="start"
          *ngIf="appState.isLoggedIn"
        >
          <div
            fxLayout="row"
            fxLayoutAlign="center center"
            class="mobile-navbar-form-field-container"
          >
            <!-- Input -->
            <input
              placeholder="{{ t('search') }}"
              formControlName="search_input"
              class="mobile-navbar-form-input"
              type="search"
            />

            <!-- Button for submit -->
            <button mat-icon-button type="submit" color="accent">
              <mat-icon>search</mat-icon>
            </button>
          </div>
        </form>

        <!-- Menu button -->
        <button
          mat-icon-button
          (click)="toggleSidenav()"
          class="mobile-navbar-menu-button"
          fxLayoutAlign="center center"
        >
          <mat-icon>{{ appState.sidenav ? "close" : "menu" }}</mat-icon>
        </button>
      </div>
    </mat-toolbar>
  </ng-container>
</ng-container>
