<ng-container *transloco="let t">
  <div class="library-container">
    <!-- Tools -->
    <div class="library-tools-container">
      <div
        class="library-tools"
        fxLayout="column"
        fxLayout.sm="row"
        fxLayout.xs="row"
      >
        <!-- Tools buttons -->
        <div
          class="library-tools-buttons-container"
          *ngFor="let button of buttons"
        >
          <mat-icon
            matTooltip="{{ t(button.toolTip) }}"
            fxLayout="column"
            color="accent"
            class="{{ button.class }}"
            [ngClass]="{ 'library-tools-active-button': button.active }"
            (click)="button.onClick()"
          >
            {{ button.icon }}
            <span class="library-tools-button-span">{{ button.title }}</span>
          </mat-icon>
        </div>
      </div>
    </div>

    <!-- List of founded entries -->
    <div
      *ngIf="entries.length > 0; else nothingFound"
      class="entries-list-container"
      fxLayout="row wrap"
      fxLayout.xs="column"
      fxLayoutGap="16px grid"
    >
      <div
        fxFlex="14.28%"
        fxFlex.md="25%"
        fxFlex.sm="33.3%"
        fxFlex.xs="100%"
        *ngFor="let entry of entries"
      >
        <app-entry-detail
          [entry]="entry"
          [show]="true"
          (onLikedChange)="reload()"
        ></app-entry-detail>
      </div>
    </div>

    <!-- IF there are no entries -->
    <ng-template #nothingFound>
      <!-- If the filter is active / only than show -->
      <div
        *ngIf="filters.isActive()"
        class="entries-list-empty"
        fxLayout="column"
      >
        {{ t("lazy.library.notFound") }}
        <mat-icon class="library-not-found-icon" (click)="clearFilter()">
          youtube_searched_for
        </mat-icon>
      </div>
    </ng-template>
  </div>
</ng-container>
