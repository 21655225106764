<ng-container *transloco="let t; read: 'navbar'">
  <ng-container *ngIf="appState$ | async as appState">
    <mat-toolbar fxLayout="row" class="navbar-container">
      <!-- First column -->
      <div
        class="navbar-first-column"
        [ngClass]="{ 'navbar-first-column-offline': !appState.isLoggedIn }"
      >
        <!-- First row  -->
        <div fxLayout="row" class="navbar-images-first-row">
          <!-- Image (Elvira) first row -->
          <img
            src="../../../../assets/logos/title_logo.png"
            class="navbar-image"
            (elviraclick)="navigate('/elvira/home', $event)"
          />

          <!-- STU logo -->
          <img
            src="../../../../assets/logos/fiit_logo.png"
            class="navbar-image-stu-logo"
            (elviraclick)="goToSTU()"
          />
        </div>

        <!-- Second row -->
        <div
          *ngIf="appState.isLoggedIn"
          fxLayout="row"
          class="navbar-buttons-container"
        >
          <!-- Home button -->
          <button
            (elviraclick)="navigate('/elvira/home', $event)"
            class="navbar-main-button"
          >
            <mat-icon class="navbar-icon">home</mat-icon>
            {{ t("home") }}
          </button>

          <!-- Library button -->
          <button
            (elviraclick)="navigateToLibrary($event)"
            class="navbar-main-button"
          >
            <mat-icon class="navbar-icon"> menu_book </mat-icon>
            {{ t("library") }}
          </button>

          <!-- My shelf button -->
          <button
            (elviraclick)="navigate('/elvira/my-shelf', $event)"
            class="navbar-main-button"
            style="position: relative"
          >
            <mat-icon class="navbar-icon">my_library_books</mat-icon>
            {{ t("myShelf") }}
            <div
              class="navbar-library-counter"
              [ngClass]="{
                'navbar-counter-visible': appState.count
              }"
            >
              {{ appState.count }}
            </div>
          </button>

          <!-- Admin button -->
          <button
            *ngIf="appState.isAdmin"
            (elviraclick)="navigate('/elvira/admin', $event)"
            class="navbar-main-button"
          >
            Admin
          </button>
        </div>
      </div>

      <!-- Second column -->
      <div class="navbar-second-column">
        <!-- First row -->
        <div fxLayout="row" class="navbar-top-row">
          <!-- Translate button -->
          <div *ngIf="appState.lang === 'en'; else enVersionBtn">
            <!-- sk -->
            <button class="navbar-button" (click)="changeLanguage('sk')">
              <mat-icon class="navbar-icon">language</mat-icon>
              <span>{{ t("eng") }}</span>
            </button>
          </div>
          <!-- en -->
          <ng-template #enVersionBtn>
            <button class="navbar-button" (click)="changeLanguage('en')">
              <mat-icon class="navbar-icon">translate</mat-icon>
              <span>{{ t("svk") }}</span>
            </button>
          </ng-template>

          <!-- Theme mode button -->
          <button class="navbar-button-theme-switcher" (click)="changeTheme()">
            <mat-icon>dark_mode</mat-icon>
            <span class="navbar-spacer"></span>
            <mat-icon>light_mode</mat-icon>
            <div
              class="navbar-button-theme-circle"
              [ngClass]="{ 'navbar-button-theme-circle-dark-mode': theme }"
            ></div>
          </button>

          <!-- Account button -->
          <ngx-avatars
            *ngIf="appState.isLoggedIn"
            [name]="name"
            size="32"
            [matMenuTriggerFor]="accountMenu"
            class="navbar-button"
          />
        </div>

        <!-- Second row -->
        <div
          *ngIf="appState.isLoggedIn"
          fxLayout="row"
          class="navbar-bottom-row"
        >
          <!-- Search form -->
          <form
            [formGroup]="search_form"
            (submit)="submit()"
            class="navbar-form"
          >
            <div
              fxLayout="row"
              fxLayoutAlign="end end"
              class="navbar-form-field-container"
            >
              <div fxFill fxLayout="start end" class="navbar-form-field">
                <!-- Input -->
                <input
                  placeholder="{{ t('search') }}"
                  formControlName="search_input"
                  class="navbar-form-input"
                  type="search"
                />
              </div>

              <!-- Button for submit -->
              <button mat-icon-button type="submit" color="accent">
                <mat-icon>search</mat-icon>
              </button>
            </div>
          </form>

          <!-- Advanced button -->
          <button (click)="openAdvanced()" class="navbar-main-button">
            {{ t("advanced") }}
          </button>
        </div>
      </div>
    </mat-toolbar>

    <!-- Account mat menu -->
    <mat-menu #accountMenu="matMenu">
      <button *ngIf="appState.isLoggedIn" mat-menu-item (click)="logout()">
        <mat-icon>logout</mat-icon>
        <span>{{ t("log-out") }}</span>
      </button>
    </mat-menu>
  </ng-container>
</ng-container>
