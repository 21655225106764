import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-project',
  templateUrl: './about-project.component.html',
  styleUrls: ['./about-project.component.scss']
})
export class AboutProjectComponent implements OnInit {

  ngOnInit(): void {
    window.scrollTo(0, 0);
  }
}
