<ng-container *transloco="let t">
  <!-- Title -->
  <h2 mat-dialog-title fxLayout="row" fxLayoutAlign="center">
    {{ isEmpty() ? t("lazy.filterDialog.empty") : t("lazy.filterDialog.name") }}
  </h2>

  <!-- Dialog content -->
  <mat-dialog-content fxLayout="column" fxLayoutAlign="start start">
    <!-- Title -->
    <div *ngIf="title" fxLayout="row" class="filter-dialog-section">
      <div class="filter-dialog-section-title">
        {{ t("lazy.filterDialog.title") }} :
      </div>
      <span class="filter-dialog-section-span">{{ title }}</span>
      <mat-icon
        class="filter-dialog-section-icon"
        (click)="removeFilter('title')"
        >close</mat-icon
      >
    </div>

    <!-- Author name -->
    <div *ngIf="author" fxLayout="row" class="filter-dialog-section">
      <div class="filter-dialog-section-title">
        {{ t("lazy.filterDialog.author") }} :
      </div>
      <span class="filter-dialog-section-span">{{ author }}</span>
      <mat-icon
        class="filter-dialog-section-icon"
        (click)="removeFilter('author')"
        >close</mat-icon
      >
    </div>

    <!-- Feed -->
    <div *ngIf="feed" fxLayout="row" class="filter-dialog-section">
      <div class="filter-dialog-section-title">
        {{ t("lazy.filterDialog.feed") }} :
      </div>
      <span class="filter-dialog-section-span">{{ feed }}</span>
      <mat-icon
        class="filter-dialog-section-icon"
        (click)="removeFilter('feed')"
        >close</mat-icon
      >
    </div>

    <!-- From -->
    <div *ngIf="from" fxLayout="row" class="filter-dialog-section">
      <div class="filter-dialog-section-title">
        {{ t("lazy.filterDialog.from") }} :
      </div>
      <span class="filter-dialog-section-span">{{ from }}</span>
      <mat-icon
        class="filter-dialog-section-icon"
        (click)="removeFilter('from')"
        >close</mat-icon
      >
    </div>

    <!-- To -->
    <div *ngIf="to" fxLayout="row" class="filter-dialog-section">
      <div class="filter-dialog-section-title">
        {{ t("lazy.filterDialog.to") }} :
      </div>
      <span class="filter-dialog-section-span">{{ to }}</span>
      <mat-icon class="filter-dialog-section-icon" (click)="removeFilter('to')"
        >close</mat-icon
      >
    </div>
  </mat-dialog-content>

  <!-- Action buttons -->
  <mat-dialog-actions fxLayout="row" fxLayoutAlign="space-evenly center">
    <!-- delete button -->
    <button mat-button mat-dialog-close (click)="onDelete()">
      <mat-icon
        matTooltip="{{ t('lazy.filterDialog.delete') }}"
        style="color: red"
      >
        delete
      </mat-icon>
    </button>

    <!-- Update button -->
    <button mat-button mat-dialog-close color="primary" (click)="onUpdate()">
      {{ t("lazy.filterDialog.update") }}
    </button>

    <!-- Cancel button -->
    <button mat-button mat-dialog-close (click)="onNoClick()" color="accent">
      {{ t("lazy.filterDialog.cancel") }}
    </button>
  </mat-dialog-actions>
</ng-container>
