<ng-container *transloco="let t">
  <div class="feed-page-container">
    <!-- Tools -->
    <div class="feed-page-tools-container">
      <div
        class="feed-page-tools"
        fxLayout="column"
        fxLayout.sm="row"
        fxLayout.xs="row"
      >
        <!-- Tools buttons -->
        <div
          class="feed-page-tools-buttons-container"
          *ngFor="let button of buttons"
        >
          <mat-icon
            matTooltip="{{ t(button.toolTip) }}"
            fxLayout="column"
            color="accent"
            class="{{ button.class }}"
            [ngClass]="{ 'feed-page-tools-active-button': button.active }"
            (click)="button.onClick()"
          >
            {{ button.icon }}
            <span class="feed-page-tools-button-span">{{ button.title }}</span>
          </mat-icon>
        </div>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayoutAlign="space-between center"
      class="feed-page-up-row"
    >
      <!-- Form for searching feeds -->
      <form
        [formGroup]="search_form"
        (submit)="applyFilter()"
        class="feed-page-search-form"
      >
        <div fxLayout="row">
          <mat-form-field color="accent" class="feed-page-search-field">
            <mat-label>
              {{ title }}
            </mat-label>

            <!-- Input -->
            <input formControlName="search_input" matInput type="search" />

            <!-- Button for clear search input -->
            <mat-icon
              *ngIf="this.search_form?.value.search_input"
              class="feed-page-search-delete-icon"
              (click)="clearSearch()"
              >clear</mat-icon
            >
          </mat-form-field>

          <!-- Button for submit -->
          <button
            mat-icon-button
            type="submit"
            color="accent"
            class="feed-page-search-button"
          >
            <mat-icon class="feed-page-search-icon">search</mat-icon>
          </button>
        </div>
      </form>

      <!-- Back button -->
      <button (click)="goBack()" mat-raised-button color="primary">
        {{ t("lazy.documentManagement.feed") }}
      </button>
    </div>

    <div class="path-names-container">
      <div *ngFor="let feed of feed_path" (click)="goTo(feed)">
        <span class="path-name">{{ feed.name }} </span>/
      </div>
    </div>

    <!-- Feeds children list -->
    <div class="feed-page-feeds-container">
      <div
        fxLayout.gt-xs="row wrap"
        fxLayout.xs="column"
        fxLayoutGap="16px grid"
      >
        <div
          fxFlex="20%"
          fxFlex.md="33.3%"
          fxFlex.sm="50%"
          fxFlex.xs="100%"
          *ngFor="let feed of feeds_children"
        >
          <app-feed [feed]="feed"></app-feed>
        </div>
      </div>
    </div>
  </div>
</ng-container>
