<div
  fxLayout="column"
  *ngIf="swiper_entries.length > 0"
  class="swiper-container"
>
  <!-- List of entries -->
  <div
    fxFlex="100%"
    *ngFor="let entry of swiper_entries; let i = index"
    class="swiper-entire-not-active"
    [ngClass]="{ 'swiper-entrie-active': selected_index === i }"
  >
    <app-entry-detail [entry]="entry" [titleRow]="true"></app-entry-detail>
  </div>

  <!-- Dots under entries -->
  <div class="swiper-dot-container">
    <span
      *ngFor="let dot of swiper_entries; let i = index"
      class="swiper-dot"
      [ngClass]="{ 'swiper-active-dot': selected_index === i }"
      (click)="selectEntrie(i)"
    >
    </span>
  </div>
</div>
